import { Swiper, SwiperSlide } from "swiper/react";
import {
  Navigation,
  Autoplay,
  Pagination,
  Scrollbar,
  A11y,
} from "swiper/modules";
import "./sliders.css";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";

const card = [
  {
    num: "01",
    img: "http://dojoitsolutions.com/wp-content/uploads/2024/08/Group-17.svg",
    title: "Web Application Development",
    subTitle:
      "We provide the best Web Application Development using the latest technologies.",
    to: "/wepApp",
  },
  {
    num: "02",
    img: "http://dojoitsolutions.com/wp-content/uploads/2024/08/Group-21.svg",
    title: "Mobile Application Development ",
    subTitle: "We can develop mobile apps using for Android and IOS.",
    to: "/mobileApp",
  },
  {
    num: "03",
    img: "http://dojoitsolutions.com/wp-content/uploads/2024/08/Group-25.svg",
    title: "SaaS Application Development",
    subTitle: "If you need a SaaS app then you are at the right place.",
    to: "/sass",
  },
  {
    num: "04",
    img: "http://dojoitsolutions.com/wp-content/uploads/2024/08/Group-32.svg",
    title: "UI/UX Designing",
    subTitle:
      "Need to design you idea? We are providing the best user experiences for your ideas.",
    to: "/uiux",
  },
  {
    num: "05",
    img: "https://dojoitsolutions.com/wp-content/uploads/2024/09/Group98.svg",
    title: "Software Application Maintenance",
    subTitle:
      "We are at your service even after the development for the support and maintenance.",
    to: "/software",
  },
  {
    num: "06",
    img: "http://dojoitsolutions.com/wp-content/uploads/2024/08/testingsilder.svg",
    title: "Software Testing & QA",
    subTitle: "We have the best team for the testing of your valued products.",
    to: "/testing",
  },
];
export default function Sliders() {
  const navigate = useNavigate();

  const handleClick = () => {
    navigate("/services");
  };
  return (
    <div id="services" className="slid">
      <div className="overlaySlid"></div>
      {/* <div className="r-mesh">
        <img className="r-star" src="/imgs/Star 1.svg"></img>
        <img className="r-rectangle" src="/imgs/Star 1.svg"></img>
        <img className="r-polygon1" src="/imgs/Polygon 1.svg"></img>
        <img className="r-polygon2" src="/imgs/Polygon 2.svg"></img>
        <img className="r-ellipse5" src="/imgs/Ellipse 5.svg"></img>
        <img className="r-ellipse6" src="/imgs/Ellipse 6.svg"></img>
      </div> */}
      <div className="container2">
        <div className="left">
          <div className="cont">
            <button className="prev">
              <img
                src="https://dojoitsolutions.com/wp-content/uploads/2024/09/arrowPre.svg"
                alt="photo"
              />
            </button>
            <button className="next">
              <img
                src="http://dojoitsolutions.com/wp-content/uploads/2024/08/arrwoNext-2.svg"
                alt="photo"
              />
            </button>
          </div>
        </div>
      </div>
      <div className="rights">
        {/* <div className="container2"> */}
        <Swiper
          modules={[Navigation, Autoplay, Pagination, Scrollbar, A11y]}
          slidesPerView="auto" // عرض 3 عناصر في كل مرة
          spaceBetween={0}
          loop={true}
          // direction={"horizontal"}
          navigation={{
            nextEl: ".next",
            prevEl: ".prev",
          }}
          autoplay={{
            delay: 4000,
            disableOnInteraction: false,
            pauseOnMouseEnter: true,
          }}
          // pagination={{ clickable: true }}
        >
          {card.map((item, index) => {
            return (
              <div key={index} className="card" onClick={handleClick}>
                <SwiperSlide>
                  <Link to={item.to}>
                    <div>
                      <h2>{item.num}</h2>
                      <div className="line4">
                        <img
                          src="https://dojoitsolutions.com/wp-content/uploads/2024/10/Line-4.svg"
                          alt="photo "
                        />
                      </div>
                      <img className="imgs" src={item.img} alt="photo" />
                      <h1 className="sliderH1">{item.title}</h1>
                      <p>{item.subTitle}</p>
                      <div className="icon">
                        <img
                          className="startIcon"
                          src="https://dojoitsolutions.com/wp-content/uploads/2024/10/Vector.svg"
                          alt="photo"
                        />
                      </div>
                    </div>
                  </Link>
                </SwiperSlide>
              </div>
            );
          })}
        </Swiper>
      </div>
    </div>

    // </div>
  );
}
